import React, { useEffect } from "react";
import { useState } from "react";
import { Route, Switch } from "react-router-dom";
import Icon from "./pages/Icon";

function App() {
  return (
    <Switch>
      <Route path="/" component={Icon} exact />
    </Switch>
  );
}

export default App;
