import React from "react";
import { RefObject } from "react";
import { useEffect } from "react";
import { useRef } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../modules";
import { CanvasContainer, CanvasType, getPixiSetting } from "../pages/Icon";
import * as PIXI from "pixi.js";
import SemicolonImage from "../assets/logox0.25.svg";
import { dlog } from "../sdlib/sdutil";

let pixiApp: PIXI.Application | null = null;
function TinyCanvas({ app, contentsSize }: CanvasType) {
  const canvasRef: RefObject<HTMLDivElement> = useRef(null);

  const contents = useSelector((state: RootState) => state.contents);

  const initialize = () => {
    if (canvasRef.current != null) {
      pixiApp = app;
      canvasRef.current.appendChild(app.view);
    }
  };

  const onDraw = () => {
    if (canvasRef.current != null) {
      if (pixiApp === null) {
        canvasRef.current.removeChild(canvasRef.current.children[0]);
        initialize();
      }

      if (pixiApp != null) {
        pixiApp.stage.removeChildren();

        if (contents.backgroundImage.source.length > 0) {
          pixiApp.renderer.backgroundColor = 0x00ffffff;
          const bgTexture = PIXI.Texture.from(contents.backgroundImage.source);
          const bg = new PIXI.Sprite(bgTexture);
          bg.x = contentsSize * 0.5;
          bg.y = contentsSize * 0.5;
          bg.anchor.set(0.5);

          let ratio =
            contents.backgroundImage.width >= contents.backgroundImage.height
              ? contentsSize / contents.backgroundImage.height
              : contentsSize / contents.backgroundImage.width;
          bg.scale.x = bg.scale.y = ratio;
          pixiApp.stage.addChild(bg);

          const mask = new PIXI.Graphics();
          mask.beginFill(0x00ffffff);
          if (contents.shape === 0) {
            mask.drawRect(0, 0, contentsSize, contentsSize);
          } else {
            mask.drawCircle(
              contentsSize * 0.5,
              contentsSize * 0.5,
              contentsSize * 0.5
            );
          }
          mask.endFill();
          bg.mask = mask;
        } else {
          pixiApp.renderer.backgroundColor = 0x00ffffff;

          const bg_color = Number(
            contents.backgroundColor.toLowerCase().replace("#", "0x")
          );
          const rect = new PIXI.Graphics();
          rect.beginFill(bg_color);
          if (contents.shape === 0) {
            rect.drawRect(0, 0, contentsSize, contentsSize);
          } else {
            rect.drawCircle(
              contentsSize * 0.5,
              contentsSize * 0.5,
              contentsSize * 0.5
            );
          }
          rect.endFill();
          pixiApp.stage.addChild(rect);
        }

        const semicolonTexture = PIXI.Texture.from(SemicolonImage);
        const semicolon = new PIXI.Sprite(semicolonTexture);
        semicolon.tint = Number(
          contents.semicolonColor.toLowerCase().replace("#", "0x")
        );
        semicolon.x = 49;
        semicolon.y = 22;
        semicolon.scale.set(0.42, 0.42);
        pixiApp.stage.addChild(semicolon);

        const basicText = new PIXI.Text(contents.name, {
          fontFamily: "IntroBlackCaps",
          fontSize: 25,
          fill: contents.foregroundColor,
        });
        // dlog(basicText.height)
        basicText.x = (contentsSize - basicText.width - 10) * 0.5;
        basicText.y = (contentsSize - 27 - 4) * 0.5;
        // basicText.y = (contentsSize - basicText.height - 4) * 0.5;
        pixiApp.stage.addChild(basicText);
      }
    }
  };

  useEffect(() => {
    if (canvasRef.current != null) {
      if (canvasRef.current.children.length === 0) {
        initialize();
      }

      setTimeout(onDraw, 1000);
    }
  }, []);

  useEffect(() => {
    setTimeout(onDraw, 500);
  }, [contents]);

  return <CanvasContainer size={contentsSize} ref={canvasRef} />;
}

export default TinyCanvas;
