const SET_BACKGROUND_COLOR = "contents/SET_BACKGROUND_COLOR" as const;
const SET_FOREGROUND_COLOR = "contents/SET_FOREGROUND_COLOR" as const;
const SET_SEMICOLON_COLOR = "contents/SET_SEMICOLON_COLOR" as const;
const SET_NAME = "contents/SET_NAME" as const;
const SET_BACKGROUND_SOURCE = "contents/SET_BACKGROUND_SOURCE" as const;
const SET_SHAPE = "contents/SET_SHAPE" as const;

export type ImageType = {
  source: string;
  width: number;
  height: number;
};

export type ContentsData = {
  backgroundImage: ImageType;
  backgroundColor: string;
  foregroundColor: string;
  semicolonColor: string;
  name: string;
  shape: number;
};

export const setBackgroundColor = (color: string) => ({
  type: SET_BACKGROUND_COLOR,
  color,
});

export const setForegroundColor = (color: string) => ({
  type: SET_FOREGROUND_COLOR,
  color,
});

export const setSemicolonColor = (color: string) => ({
  type: SET_SEMICOLON_COLOR,
  color,
});

export const setName = (name: string) => ({
  type: SET_NAME,
  name,
});

export const setBackgroundSource = (image: ImageType) => ({
  type: SET_BACKGROUND_SOURCE,
  image,
});

export const setShape = (shape: number) => ({
  type: SET_SHAPE,
  shape,
});

const initialState: ContentsData = {
  backgroundImage: {
    source: "",
    width: 0,
    height: 0,
  },
  backgroundColor: "#000000",
  foregroundColor: "#ffffff",
  semicolonColor: "#ffffff",
  name: "OK",
  shape: 0,
};

type ContentsAction =
  | ReturnType<typeof setBackgroundColor>
  | ReturnType<typeof setForegroundColor>
  | ReturnType<typeof setSemicolonColor>
  | ReturnType<typeof setName>
  | ReturnType<typeof setBackgroundSource>
  | ReturnType<typeof setShape>;

export default function contents(state = initialState, action: ContentsAction) {
  switch (action.type) {
    case SET_BACKGROUND_COLOR:
      return {
        ...state,
        backgroundColor: action.color,
      };
    case SET_FOREGROUND_COLOR:
      return {
        ...state,
        foregroundColor: action.color,
      };
    case SET_SEMICOLON_COLOR:
      return {
        ...state,
        semicolonColor: action.color,
      };
    case SET_NAME:
      return {
        ...state,
        name: action.name,
      };
    case SET_BACKGROUND_SOURCE:
      return {
        ...state,
        backgroundImage: action.image,
      };
    case SET_SHAPE:
      return {
        ...state,
        shape: action.shape,
      }
    default:
      return state;
  }
}
