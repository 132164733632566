import { Button, Checkbox, Select, TextField } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { ColorResult, SketchPicker } from "react-color";
import { dlog } from "../sdlib/sdutil";
import * as PIXI from "pixi.js";
import { MenuItem } from "@material-ui/core";
import JSZip from "jszip";
import SampleImage from "../assets/sample.png";
import BigCanvas from "../components/BigCanvas";
import NormalCanvas from "../components/NormalCanvas";
import SmallCanvas from "../components/SmallCanvas";
import TinyCanvas from "../components/TinyCanvas";
import { useDispatch, useSelector } from "react-redux";
import {
  setBackgroundColor,
  setBackgroundSource,
  setForegroundColor,
  setName,
  setSemicolonColor,
  setShape,
} from "../modules/contents";
import { RootState } from "../modules";
import GiantCanvas from "../components/GiantCanvas";

const Root = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  overflow-y: scroll;
`;

const Container = styled.div`
  padding: 0px 20px;
  margin: 10px;
`;

const HorizontalLine = styled.div`
  display: flex;
  align-items: center;
  margin: 15px 0px;
`;

const VerticalLine = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 20px;
`;

const ItemLabel = styled.label`
  margin-right: 20px;
  width: fit-content;
`;

const ItemField = styled(TextField)`
  flex: 1;
`;

const ColorPicker = styled(SketchPicker)`
  margin: 10px;
`;

const ItemContainer = styled.div<{ size: number }>`
  position: relative;
  width: ${(props) => props.size + "px"};
  height: ${(props) => props.size + "px"};
  pointer-events: none;
  margin-right: 50px;
`;

export const CanvasContainer = styled.div<{ size: number }>`
  /* position: relative; */
  position: absolute;
  width: ${(props) => props.size + "px"};
  height: ${(props) => props.size + "px"};
  pointer-events: none;
  /* transform: scale(0.5, 0.5);
  transform-origin: 0 10; */
`;

const MarginArea = styled.div`
  height: 30px;
`;

const SampleImageContainer = styled.img<{ size: number }>`
  /* position: absolute; */
  width: ${(props) => props.size + "px"};
  height: ${(props) => props.size + "px"};
  opacity: 0.2;
  /* transform: scale(0.5, 0.5);
  transform-origin: 0 10; */
  opacity: 0.01;
`;

const ItemSelect = styled(Select)`
  flex: 1;
`;

const MarginWidth = styled.div`
  width: 40px;
`;

export const getPixiSetting = (size: number) => {
  return new PIXI.Application({
    backgroundColor: 0xffffff,
    width: size,
    height: size,
    antialias: true,
    resolution: 1,
    autoDensity: true,
  });
};

export type CanvasType = {
  app: PIXI.Application;
  contentsSize: number;
};

let pixiApp: PIXI.Application | null = null;

let giant_pixi: PIXI.Application = getPixiSetting(1024);
let big_pixi: PIXI.Application = getPixiSetting(512);
let normal_pixi: PIXI.Application = getPixiSetting(256);
let small_pixi: PIXI.Application = getPixiSetting(128);
let tiny_pixi: PIXI.Application = getPixiSetting(64);
let pixi = [giant_pixi, big_pixi, normal_pixi, small_pixi, tiny_pixi];

function Icon() {
  const dispatch = useDispatch();
  const [size, setSize] = useState({
    has_1024: false,
    has_512: true,
    has_256: false,
    has_128: false,
    has_64: false,
  });
  const [changeColor, setEqualColor] = useState(false);
  const { backgroundColor, foregroundColor, semicolonColor, name, shape } =
    useSelector((state: RootState) => state.contents);

  const onChangeName = (e: React.ChangeEvent<HTMLInputElement>) => {
    let value = e.target.value;

    if (value.length > 2) return;

    if (value.length > 0) {
      let res = /^[a-z][a-z\d]*$/i.test(value);
      if (!res) return;
    }

    dispatch(setName(value.toUpperCase()));
  };

  const onChangeBackgroundColor = (result: ColorResult) => {
    const back = {
      source: "",
      width: 0,
      height: 0,
    };
    dispatch(setBackgroundSource(back));
    dispatch(setBackgroundColor(result.hex));
  };

  const onChangeForegroundColor = (result: ColorResult) => {
    dispatch(setForegroundColor(result.hex));
    if (!changeColor) {
      dispatch(setSemicolonColor(result.hex));
    }
  };

  const onChangeSemicolonColor = (result: ColorResult) => {
    dispatch(setSemicolonColor(result.hex));
  };

  const onChangeOption = (e: React.ChangeEvent<HTMLInputElement>) => {
    const is_checked = e.target.checked;
    setEqualColor(is_checked);
    if (!is_checked) {
      dispatch(setSemicolonColor(foregroundColor));
    }
  };

  const onSelect = (e: React.ChangeEvent<{ value: unknown }>) => {
    let shape = e.target.value as number;
    dispatch(setShape(shape));
  };

  const onChangeFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target != null && e.target.files != null) {
      const file = e.target.files[0];

      if (file != null) {
        const reader = new FileReader();
        reader.onload = (e: any) => {
          const result = e.target.result;
          let image = new Image();
          image.src = result;

          image.onload = () => {
            const data = {
              source: image.src,
              width: image.width,
              height: image.height,
            };
            dispatch(setBackgroundSource(data));
          };
        };
        reader.readAsDataURL(file);
        return;
      }
    }

    const back = {
      source: "",
      width: 0,
      height: 0,
    };
    dispatch(setBackgroundSource(back));
  };

  const onSave = () => {
    dlog("Save", size);
    const zip = new JSZip();

    let scale_list = [] as number[];
    if (size.has_1024) scale_list = [...scale_list, 1024];
    if (size.has_512) scale_list = [...scale_list, 512];
    if (size.has_256) scale_list = [...scale_list, 256];
    if (size.has_128) scale_list = [...scale_list, 128];
    if (size.has_64) scale_list = [...scale_list, 64];

    if (scale_list.length === 0) scale_list = [512];

    if (scale_list.length === 1) {
      pixi[0].renderer.plugins.extract
        .canvas(pixi[0].stage)
        .toBlob(async (blob: any) => {
          const a = document.createElement("a") as HTMLAnchorElement;
          a.download = `${name}x${scale_list[0]}.png`;
          a.href = URL.createObjectURL(blob);
          a.click();
          a.remove();
        }, "image/png");
      return;
    }

    const resizeItem = (index: number) => {
      pixi[index].renderer.plugins.extract
        .canvas(pixi[index].stage)
        .toBlob(async (blob_data: any) => {
          zip.file(`${name}x${scale_list[index]}.png`, blob_data, {
            base64: true,
          });

          if (index === scale_list.length - 1) {
            zip.generateAsync({ type: "blob" }).then(function (content) {
              const a = document.createElement("a") as HTMLAnchorElement;
              a.download = `${name}.zip`;
              a.href = URL.createObjectURL(content);
              a.click();
              a.remove();
            });
          } else {
            resizeItem(index + 1);
          }
        }, "image/png");
    };

    resizeItem(0);
  };

  const onSaveIco = () => {
    let pngToIco = (window as any).pngToIco;

    if (pixiApp != null) {
      pixiApp.renderer.plugins.extract
        .canvas(pixiApp.stage)
        .toBlob(async (b: any) => {
          let img_data = new Uint8Array(b);
          let icoData = pngToIco(img_data), //array of bytes
            type = "image/x-ico",
            blob = new Blob([new Uint8Array(icoData)], { type: type });
          const a = document.createElement("a") as HTMLAnchorElement;
          a.download = `${name}.ico`;
          a.href = window.URL.createObjectURL(blob);
          a.dataset.downloadurl = [type, a.download, a.href].join(":");
          a.click();
          a.remove();
        }, "image/png");
    } else {
      alert("[Error] Can't download");
    }
  };

  const onChangeSize = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSize({
      ...size,
      [e.target.name]: e.target.checked,
    });
  };

  return (
    <Root>
      <Container>
        <h1 style={{ fontFamily: "IntroBlackCaps" }}>I M Icon</h1>
        <div>Version ({process.env.REACT_APP_VERSION_CODE})</div>

        <MarginArea />

        <HorizontalLine>
          <ItemLabel>Name : </ItemLabel>
          <ItemField
            value={name}
            onChange={onChangeName}
            inputProps={{ maxLength: 2 }}
          />
        </HorizontalLine>

        <HorizontalLine>
          <ItemLabel>Shape : </ItemLabel>
          <ItemSelect value={shape} onChange={onSelect}>
            <MenuItem value={0}>Rectangle</MenuItem>
            <MenuItem value={1}>Circle</MenuItem>
          </ItemSelect>
        </HorizontalLine>

        <HorizontalLine>
          <Checkbox id="color" value={changeColor} onChange={onChangeOption} />
          <label htmlFor="color">Change Semicolon color</label>
        </HorizontalLine>

        <HorizontalLine>
          <VerticalLine>
            <label htmlFor="">Background</label>
            <ColorPicker
              disableAlpha
              color={backgroundColor}
              onChange={onChangeBackgroundColor}
            />
          </VerticalLine>

          <VerticalLine>
            <label htmlFor="">Foreground</label>
            <ColorPicker
              disableAlpha
              color={foregroundColor}
              onChange={onChangeForegroundColor}
            />
          </VerticalLine>

          {changeColor && (
            <VerticalLine>
              <label htmlFor="">Semicolon</label>
              <ColorPicker
                disableAlpha
                color={semicolonColor}
                onChange={onChangeSemicolonColor}
              />
            </VerticalLine>
          )}
        </HorizontalLine>

        <HorizontalLine>
          <ItemLabel>Background Image : </ItemLabel>
          <input accept="image/*" type="file" onChange={onChangeFile} />
        </HorizontalLine>

        <HorizontalLine>
          <div>Size : </div>

          <MarginWidth />

          <Checkbox
            id="1024"
            name="has_1024"
            onChange={onChangeSize}
            checked={size.has_1024}
          />
          <label htmlFor="1024">1024</label>

          <MarginWidth />

          <Checkbox
            id="512"
            name="has_512"
            onChange={onChangeSize}
            checked={size.has_512}
          />
          <label htmlFor="512">512</label>

          <MarginWidth />

          <Checkbox
            id="256"
            name="has_256"
            onChange={onChangeSize}
            checked={size.has_256}
          />
          <label htmlFor="256">256</label>

          <MarginWidth />

          <Checkbox
            id="128"
            name="has_128"
            onChange={onChangeSize}
            checked={size.has_128}
          />
          <label htmlFor="128">128</label>

          <MarginWidth />

          <Checkbox
            id="64"
            name="has_64"
            onChange={onChangeSize}
            checked={size.has_64}
          />
          <label htmlFor="64">64</label>
        </HorizontalLine>

        <MarginArea />

        <HorizontalLine>
          <Button variant="contained" onClick={onSave}>
            Save To PNG
          </Button>

          {/* <MarginWidth />
          <Button variant="contained" onClick={onSaveIco}>
            Save TO ICO
          </Button> */}
        </HorizontalLine>

        <HorizontalLine style={{ alignItems: "normal" }}>
          <ItemContainer size={1024}>
            <GiantCanvas app={giant_pixi} contentsSize={1024} />
            <SampleImageContainer src={SampleImage} size={1024} />
          </ItemContainer>

          <ItemContainer size={512}>
            <BigCanvas app={big_pixi} contentsSize={512} />
            <SampleImageContainer src={SampleImage} size={512} />
          </ItemContainer>

          <ItemContainer size={256}>
            <NormalCanvas app={normal_pixi} contentsSize={256} />
            <SampleImageContainer src={SampleImage} size={256} />
          </ItemContainer>

          <ItemContainer size={128}>
            <SmallCanvas app={small_pixi} contentsSize={128} />
            <SampleImageContainer src={SampleImage} size={128} />
          </ItemContainer>

          <ItemContainer size={64}>
            <TinyCanvas app={tiny_pixi} contentsSize={64} />
            <SampleImageContainer src={SampleImage} size={64} />
          </ItemContainer>
        </HorizontalLine>
      </Container>
    </Root>
  );
}

export default Icon;
